.files {
  .file {
    margin-top: 8px;
    padding: 3px 10px;
    border: 1px solid #EEE;
    border-radius: 5px;
    display: inline-block;

    &:not(:first-child) {
      margin-left: 5px;
    }
  }

  .remove-file {
    color: red;
    cursor: pointer;
    font-weight: bolder;
    margin-left: 5px;
    font-size: 11px;
  }
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .primary {
    min-width: 200px;
  }
}

.messages {
  .message-text {
    white-space: pre-wrap;
  }

  .message-head {
    margin-bottom: 6px;
  }

  .message-date {
    font-size: 11px;
  }

  .message {
    margin: 14px;
    padding-left: 10px;
    border-left: 5px solid #CCC;

    &.customer {
      border-left: 5px solid #2da3b8;
    }

    &.support {
      border-left: 5px solid #25b875;
    }
  }

  .message-attachments {
    .attch {
      margin-top: 10px;

      &:not(:first-child) {
        margin-left: 5px;
      }

      padding: 3px 10px;
      border: 1px solid #CCC;
      border-radius: 5px;
      display: inline-block;
    }
  }
}