body {
  background: #fcfcfc !important;
}

.top-menu {

  .header.item {
    padding: 8px 10px 8px 0;

    border-left: none !important;

    > div {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 10px 12px;
      border-radius: 10px;
    }

    span {
      margin-left: 10px;
      font-weight: 800;
    }
  }

  .userEmail {
    font-weight: 100;
    font-size: 11px;
  }

  background-color: #1f81b0 !important;
}

.main.container {
  margin-top: 70px;
}

.breadcrumbs {
  margin-bottom: 20px;
}