
.bg-light-red {
  background-color: #fcf0f1 !important;
}

.bg-light-orange {
  background-color: #faf1ed !important;
}

.bg-light-yellow {
  background-color: #fcf8ed !important;
}

.bg-light-blue {
  background-color: #eef6f9 !important;
}

.bg-light-green {
  background-color: #f9fbed !important;
}

.locators {
  font-weight: 800;
}